import * as React from "react"
import { graphql, Link } from "gatsby"
import SmallHero from "../components/SmallHero/SmallHero"
import G from "../images/groenhout_g.png"
import ContentImageLeft from "../components/ContentImageLeft/ContentImageLeft"
import CijfersCard from "../components/OverOns/CijfersCard/CijfersCard"
import Container from "../components/Container/Container"
import Recents from "../components/Recents/Recents"
import assenImage from "../images/groenhout-assen-1.jpg"
import beilenImage from "../images/groenhout-beilen-1.jpg"
import hoogeveenImage from "../images/Groenhout-Hoogeveen-Team.png"
import Seo from "../components/Seo/Seo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faArrowRight } from "@fortawesome/free-solid-svg-icons"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"
import CtaButton from "../components/CtaButton/cta"
import Testimonial from "../components/Testimonials/Testimonials"
import { StaticImage } from "gatsby-plugin-image"

const LandingPage = ({data}) => {

  const fundaAankoop = data.wpVestiging.vestigingen.fundaAankoopCijfer
  const fundaVerkoop = data.wpVestiging.vestigingen.fundaVerkoopCijfer
  const googleCijfer = data.wpVestiging.vestigingen.googleReviewCijfer
  const image  = data.wpVestiging.featuredImage !== null ? data.wpVestiging.featuredImage.node.mediaItemUrl : ``
  // const img = data.wpVestiging.title === `Assen` ? assenImage : data.wpVestiging.title === `Beilen` ? beilenImage : data.wpVestiging.title === `Hoogeveen` ? hoogeveenImage : null
  const imgAlt = data.wpVestiging.title === `Assen` ? `Makelaar Jozef Groenhout van Groenhout Assen` : data.wpVestiging.title === `Beilen` ? `Het team van Groenhout Beilen. Van links naar rechts: Angela, Richard, Jan-Willem.` : data.wpVestiging.title === `Hoogeveen` ? `Het team van Groenhout Hoogeveen.` : null
  const usps = data.wpVestiging.vestigingen.usps !== null ? data.wpVestiging.vestigingen.usps.map((e)=> e.usp) : null
  const cta = data.wpVestiging.vestigingen.cta ? <Link to={data.wpVestiging.vestigingen.ctaTarget} className="inline-block"><span className="text-white bg-red w-auto inline py-4 px-8 hover:pr-5 mt-4 flex flex-row justify-between items-center fullBtn"><span className="buttonHoverWhite">{data.wpVestiging.vestigingen.ctaTekst}</span></span></Link> : null
  
  console.log(data.wpVestiging.vestigingen.subtekst)

  return(
    <>
      <Seo title={data.wpVestiging.seo.title} description={data.wpVestiging.seo.metaDesc}></Seo>
      <SmallHeroNew title={`Groenhout Makelaars ${data.wpVestiging.title}`} overlay={true} overlayImage={G} image={image !== undefined ? image : ''}></SmallHeroNew>
      <div className="bg-light py-8 lg:py-16">
        <Container style={``}>
          <div className="flex flex-col items-center px-8 lg:px-0">
            <h2 className="text-center text-3xl text-blue w-full lg:w-1/2 mx-auto">Dé makelaar in <span className="text-blue underlineRed">{data.wpVestiging.title}</span></h2>
            <div className="lg:w-3/5 text-center mx-auto mt-4 text-black" dangerouslySetInnerHTML={{__html: data.wpVestiging.vestigingen.subtekst}}></div>
            {
              data.wpVestiging.title === `Beilen` ?
                <a href="https://ikzoekdebestemakelaar.nl/makelaars/beilen" target="_blank" className="mb-5">
                  <StaticImage src={'../images/beilen-groot.png'} width={200} />
                </a>
              : null
            }
            <div className="inline-block mt-4"><CtaButton target={'/diensten'} title="Meer over onze diensten" icon={faArrowRight}></CtaButton></div>
          </div>
        </Container>
      </div>
      <Recents vestiging={data.wpVestiging.title}></Recents>
      <Testimonial vestiging={data.wpVestiging.title}></Testimonial>
      <div className="lg:px-0 px-5">
        <Container>
          <p dangerouslySetInnerHTML={{__html: data.wpVestiging.content}} className="px-5 xl:w-7/12 lg:8/12 md:w-10/12 mt-16 mx-auto post"></p>
        </Container>
      </div>
    </>
    
  )

}

export default LandingPage;

export const landingQuery = graphql`
  query($id: Int!){
    wpVestiging(databaseId: {eq: $id}){
      title
      seo{
        metaDesc
        title
      }
      content
      vestigingen{
        homepageHeaderAfbeeldingVestigingSpecifiek {
          mediaItemUrl
        }
        cta
        ctaTarget
        ctaTekst
        vestigingAdres
        vestigingEmailadres
        vestigingPostcode
        vestigingTelefoonnummer
        contactpersoon
        fundaAankoopCijfer
        fundaVerkoopCijfer
        googleReviewCijfer
        subtekst
        usps {
          usp
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
  `