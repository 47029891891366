import React from "react";

const CijfersCard = (props) => {
    const active = props.active
    const cijfer = props.cijfer
    const bron = props.bron
    const max = props.max

    return(
        <div className={`lg:w-4/12 w-full lg:mt-0 mt-8 ${active ? `bg-blue text-white` : `bg-light text-blue`} p-12 lg:mr-12`}>
            <p className="xl:text-5xl lg:text-4xl text-3xl font-black m-0">{cijfer} {max !== undefined ? `/ ${max}` : null}</p>
            <p className="m-0 pt-4">{bron}</p>
        </div>
    )
}

export default CijfersCard;